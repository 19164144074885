$base: var(--base, 8px);

.bottom-sidebar .c-contact-banner , .bottom-sidebar .modularity-map-container {
    width: 100%;
    max-width: 1248px;
    margin: 0 auto;

    .c-card {
        margin: 0 calc(var(--base, 8px) * 4);
        .c-group .c-card {
            margin:0;
        }
    }
}

.c-contact-banner {
    margin-bottom: calc(#{$base} * 8);

    &__info-container {
        display: flex;
        flex-direction: column;
        padding: 0 calc(#{$base} * 4);
        margin: calc(#{$base} * 6) 0;
    }

    &__title {
        font-size: calc(#{$base} * 4.5);
        color: var(--color-mono-darkest, #3D3D3D);
        &:first-child {
            margin-top: -0.2em;
        }
    }

    &__text {
        color: var(--color-mono-darkest, #3D3D3D);
    }

    &__hours {
        margin-top: calc(#{$base} * 4);

        p:not(:first-of-type) {
            margin-top: 0;
        }
    }
    
    &__weekdays {
        font-weight: var(--font-weight-bold);
        font-family: var(--font-family-bold);
    }

    &__hours-title {
        font-size: 1.125rem;
    }

    &__weekdays {
        font-size: 1rem;
    }

    &__abnormal-hours {
        font-size: 0.875rem;
    }

    .c-card {
        .c-card {
            width: 35%;
            display: flex;
            flex-direction: column;
            box-shadow: none;
            border-radius: 0px;
            background-color: transparent;
            border-right: var(--border-width-divider, 1px) solid  var(--color-border-divider, #E5E5E5);
        }
        &:last-child  {
            border-right: none;
        }
    }

    .c-card--flat {
        & > *:first-child {
            filter: none; 
        }  
    }

    .c-card__body {
        flex-grow: 1;
        color: var(--color-darkest, #3d3d3d);
    }

    .c-card__header {
        display: flex;
        margin-bottom: calc(#{$base} * 3);

        h4 {
            margin-top: 0px;
        }
    }

    
    @media screen and (max-width: 896px) {

        &__footer {
            border: none;
        }

        .c-group {
            display: block;
        }

        .c-card .c-card {
            width:100%;
            border-right: none;
            border-bottom: var(--border-width-divider, 1px) solid var(--color-border-divider, #E5E5E5);
            &:last-child {
                border-bottom: none;
            }
        }
    }

    @media screen and (min-width: 896px) {

        &__info-container {
            flex-direction: row;
        }

        &__hours {
            max-width: 30%;
            margin-top: 0;
            margin-left: auto;
            border-left: 2px solid var(--color-mono-lighter, #E5E5E5);
            padding-left: calc(#{$base} * 3);
        }

        &__content {
            max-width: 50%;
            height: max-content;
            margin: 0;
        }
    }
}

.s-footer {
    .c-card__header {
        h4 {
            font-size: 1.5rem;
        }

        .c-icon {
            font-size: calc(#{$base}*4);
        }
    }
}